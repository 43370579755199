<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-row
          align="center"
          justify="center"
          style="height: 50vh;"
        >
          <v-card
            class="ma-3 pa-10"
            style="width: 400px"
            outlined
          >
            <img src="../../assets/hsf-logo.jpg">
            <div v-if="user.logged">
              <v-form
                v-model="formValid"
                @submit.prevent="performRegistration()"
              >
                <v-text-field
                  v-model="user.name"
                  :label="$t('fullname')"
                  class="mt-10"
                  :rules="nameRules"
                  clearable
                />
                <v-text-field
                  v-model="user.username"
                  :label="$t('username')"
                  class="mt-2"
                  readonly
                />
                <v-text-field
                  v-model="user.password"
                  type="password"
                  :label="$('password')"
                  clearable
                />
                <v-text-field
                  v-model="user.confirmPassword"
                  type="password"
                  :label="$t('password_confirm')"
                  :rules="[(user.password === user.confirmPassword) || $t('passwords-must-match')]"
                  clearable
                  autocomplete="new-password"
                />
                <v-btn
                  type="submit"
                  :disabled="!formValid"
                  block
                  large
                  color="primary"
                  dark
                >
                  {{ $t('register-and-login') }}
                </v-btn>
              </v-form>
            </div>
            <div v-else>
              <v-form @submit.prevent="performLookup()">
                <v-text-field
                  v-model="state.email"
                  :label="$('email')"
                  class="mt-2"
                />
                <v-btn
                  type="submit"
                  block
                  large
                  color="primary"
                  dark
                >
                  {{ $t('continue') }}
                </v-btn>
              </v-form>
            </div>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar.visible"
      :color="snackbar.color"
    >
      {{ snackbar.text }}

      <template #action="{ attrs }">
        <v-btn
          color="dark"
          text
          v-bind="attrs"
          @click="snackbar.visible = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
export default {
  name: 'RegisterComponent',
  data: () => ({
    formValid: false,
    state: {
      email: '',
      invitation: null
    },
    user: {
      name: '',
      username: '',
      password: '',
      confirmPassword: '',
      logged: false
    },
    invitationData: null,
    nameRules: [
      v => !!v || this.$t('name-rule')
    ],
    snackbar: {
      visible: false,
      color: 'light',
      text: ''
    }
  }),
  mounted () {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      if (this.$route.params.invitation) {
        this.state.invitation = this.$route.params.invitation
      } else {
        this.snackbar.color = 'red'
        this.snackbar.text = this.$t('invitation-invalid')
        this.snackbar.visible = true
      }
      if (!this.user.email) {
        console.log('email not set')
      }
    }
  },
  methods: {
    performRegistration () {
      firebase.auth().currentUser.updatePassword(this.user.password).then(() => {
        this.snackbar.color = 'success'
        this.snackbar.text = this.$t('invitation-success')
        this.snackbar.visible = true

        firebase.firestore().collection('users').doc(firebase.auth()
          .currentUser.uid).set({
          name: this.user.name,
          club: this.invitationData.club,
          role: this.invitationData.role
        })
        firebase.auth().currentUser.updateProfile({
          displayName: this.user.name
        }).then(() => {
          this.snackbar.visible = false
          this.$router.replace('/dashboard')
        })
      })
    },
    performLookup () {
      firebase.auth().signInWithEmailLink(this.state.email, window.location.href)
        .then(() => {
          const authenticated = firebase.auth().currentUser
          this.user.username = authenticated.email
          this.user.logged = true

          const docRef = firebase.firestore().collection('invitations').doc(this.state.invitation)
          docRef.get().then((doc) => {
            if (doc.exists) {
              this.invitationData = doc.data()
              this.snackbar.color = 'success'
              this.snackbar.text = this.$t('invitation-found')
              this.snackbar.visible = true
            } else {
              this.snackbar.color = 'red'
              this.snackbar.text = this.$t('invitation-not-found')
              this.snackbar.visible = true
            }
          })
        })
        .catch((error) => {
          this.snackbar.color = 'red'
          this.snackbar.text = error.message
          this.snackbar.visible = true
        })
    }
  }
}
</script>
